/****************************** components ******************************/

// @import "./components/mixin";
// @import "./components/default";
// @import "./components/normalize";
// @import "./components/reset";
// @import "./components/grid";
// @import "./components/case"; //例子

/****************************** style ******************************/

body,
button,
input,
select,
textarea {
    font: 12px/1.4 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #5a5a5a;
    font-size: 12px;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

body,
button,
input,
select,
textarea {
    font: .24rem/1.14 "\5FAE\8F6F\96C5\9ED1", "arial";
    color: #8e8e8e;
    font-size: .24rem;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

ul {
    list-style: none;
}

a,
button,
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="file"] {
    cursor: pointer;
}

a {
    color: inherit;
    color: expression(this.parentNode.currentStyle.color);
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%;
}

small {
    font-size: 12px;
}


/****************************** scss ******************************/

.ml5 {
    margin-left: 5px;
}

.mt5 {
    margin-top: 5px;
}

.mr5 {
    margin-right: 5px;
}

.mb5 {
    margin-bottom: 5px;
}

.mg5 {
    margin: 5px;
}

.pl5 {
    padding-left: 5px;
}

.pt5 {
    padding-top: 5px;
}

.pr5 {
    padding-right: 5px;
}

.pb5 {
    padding-bottom: 5px;
}

.pd5 {
    padding: 5px;
}

.ml10 {
    margin-left: 10px;
}

.mt10 {
    margin-top: 10px;
}

.mr10 {
    margin-right: 10px;
}

.mb10 {
    margin-bottom: 10px;
}

.mg10 {
    margin: 10px;
}

.pl10 {
    padding-left: 10px;
}

.pt10 {
    padding-top: 10px;
}

.pr10 {
    padding-right: 10px;
}

.pb10 {
    padding-bottom: 10px;
}

.pd10 {
    padding: 10px;
}

.ml15 {
    margin-left: 15px;
}

.mt15 {
    margin-top: 15px;
}

.mr15 {
    margin-right: 15px;
}

.mb15 {
    margin-bottom: 15px;
}

.mg15 {
    margin: 15px;
}

.pl15 {
    padding-left: 15px;
}

.pt15 {
    padding-top: 15px;
}

.pr15 {
    padding-right: 15px;
}

.pb15 {
    padding-bottom: 15px;
}

.pd15 {
    padding: 15px;
}

.ml20 {
    margin-left: 20px;
}

.mt20 {
    margin-top: 20px;
}

.mr20 {
    margin-right: 20px;
}

.mb20 {
    margin-bottom: 20px;
}

.mg20 {
    margin: 20px;
}

.pl20 {
    padding-left: 20px;
}

.pt20 {
    padding-top: 20px;
}

.pr20 {
    padding-right: 20px;
}

.pb20 {
    padding-bottom: 20px;
}

.pd20 {
    padding: 20px;
}

.ml25 {
    margin-left: 25px;
}

.mt25 {
    margin-top: 25px;
}

.mr25 {
    margin-right: 25px;
}

.mb25 {
    margin-bottom: 25px;
}

.mg25 {
    margin: 25px;
}

.pl25 {
    padding-left: 25px;
}

.pt25 {
    padding-top: 25px;
}

.pr25 {
    padding-right: 25px;
}

.pb25 {
    padding-bottom: 25px;
}

.pd25 {
    padding: 25px;
}

.ml30 {
    margin-left: 30px;
}

.mt30 {
    margin-top: 30px;
}

.mr30 {
    margin-right: 30px;
}

.mb30 {
    margin-bottom: 30px;
}

.mg30 {
    margin: 30px;
}

.pl30 {
    padding-left: 30px;
}

.pt30 {
    padding-top: 30px;
}

.pr30 {
    padding-right: 30px;
}

.pb30 {
    padding-bottom: 30px;
}

.pd30 {
    padding: 30px;
}

.ml35 {
    margin-left: 35px;
}

.mt35 {
    margin-top: 35px;
}

.mr35 {
    margin-right: 35px;
}

.mb35 {
    margin-bottom: 35px;
}

.mg35 {
    margin: 35px;
}

.pl35 {
    padding-left: 35px;
}

.pt35 {
    padding-top: 35px;
}

.pr35 {
    padding-right: 35px;
}

.pb35 {
    padding-bottom: 35px;
}

.pd35 {
    padding: 35px;
}

.ml40 {
    margin-left: 40px;
}

.mt40 {
    margin-top: 40px;
}

.mr40 {
    margin-right: 40px;
}

.mb40 {
    margin-bottom: 40px;
}

.mg40 {
    margin: 40px;
}

.pl40 {
    padding-left: 40px;
}

.pt40 {
    padding-top: 40px;
}

.pr40 {
    padding-right: 40px;
}

.pb40 {
    padding-bottom: 40px;
}

.pd40 {
    padding: 40px;
}

.ml45 {
    margin-left: 45px;
}

.mt45 {
    margin-top: 45px;
}

.mr45 {
    margin-right: 45px;
}

.mb45 {
    margin-bottom: 45px;
}

.mg45 {
    margin: 45px;
}

.pl45 {
    padding-left: 45px;
}

.pt45 {
    padding-top: 45px;
}

.pr45 {
    padding-right: 45px;
}

.pb45 {
    padding-bottom: 45px;
}

.pd45 {
    padding: 45px;
}

.ml50 {
    margin-left: 50px;
}

.mt50 {
    margin-top: 50px;
}

.mr50 {
    margin-right: 50px;
}

.mb50 {
    margin-bottom: 50px;
}

.mg50 {
    margin: 50px;
}

.pl50 {
    padding-left: 50px;
}

.pt50 {
    padding-top: 50px;
}

.pr50 {
    padding-right: 50px;
}

.pb50 {
    padding-bottom: 50px;
}

.pd50 {
    padding: 50px;
}


/* @extend .color; 引用样式*/


/* @include transition; 引用混合*/


/****************************** default ******************************/

.border-box,
.border-box *,
.border-box *:before,
.border-box *:after {
    box-sizing: border-box;
}

.fc {
    color: #ff1d1f;
}

.bg {
    background: #f7f7f7;
}

.center {
    padding: 0 .18rem;
}

.fl {
    float: left;
}

.fr {
    float: right;
}

.clear {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.clearfix:after {
    content: '';
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.ellipsis,
.list_new h2 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.tac {
    text-align: center;
}

.big:hover,
.bigger {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: scale .5s;
    transition: scale .5s;
}

.mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
}

.content img {
    max-width: 100%;
}

.last_flex,
.ibox1 ul:after,
.screening > ul:after {
    -moz-text-align-last: auto;
    text-align-last: auto;
    text-justify: auto;
    text-align: left;
    letter-spacing: normal;
    word-spacing: normal;
    margin: 0 !important;
    padding: 0 !important;
}

.flex_space_between,
.ilist,
.ibar ul,
.ibox7 ul,
.screening > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}


/****************************** style ******************************/

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track-piece {
    background-color: #eee;
}

::-webkit-scrollbar-thumb {
    background-color: #eee;
}

html {
    height: 100%;
}

body {
    min-height: 100%;
    position: relative;
}

.w1200 {
    width: 95%;
    margin: 0 auto;
}

.header {
    z-index: 999;
    width: 100%;
    line-height: .89rem;
    background: #fff;
    text-align: center;
    color: #20bdd8;
    font-size: .36rem;
    margin-bottom: .3rem;
}

.header img{
    float: left;
    height: .89rem;
}

.logo1{
    height: .8rem;
    margin-left: .4rem;
}

.logo2{
    height: .8rem;
}

.header .back,
.header .menu {
    display: block;
    width: .76rem;
    height: .89rem;
    position: absolute;
    top: 0;
    bottom: 0;
    cursor: pointer;
    font-size: 0;
    z-index: 2999;
}

.header .back i,
.header .menu i {
    vertical-align: middle;
}

.header .logo {
    width: 2.73rem;
    height: .73rem;
}

.header .back {
    left: 0;
}

.header .back i {
    font-size: .6rem;
}

.header .menu {
    right: 0;
    background: #20bdd8;
}

.header .menu i {
    font-size: .46rem;
    color: #fff;
}

.nav {
    display: none;
    width: 100%;
    height: 4.2rem;
    position: fixed;
    top: .89rem;
    bottom: 0;
    right: 0;
    z-index: 9999;
    background: transparent;
    -webkit-transition: background .2s;
    transition: background .2s;
}

.mask{
    display: none;
    width: 100%;
    height: 100%;
    position: fixed;
    top: .89rem;
    left: 0;
    z-index: 1999;
    background: transparent;
    -webkit-transition: background .2s;
    transition: background .2s;
    background-color: #333;
    opacity: .3;
}

.nav > ul {
    background: #20bdd8;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    overflow-x: hidden;
    -webkit-transition: right .2s;
    transition: right .2s;
    opacity: 1;
}

.nav > ul li {
    position: relative;
}

.nav > ul li a {
    display: block;
    height: .6rem;
    line-height: .6rem;
    color: #fff;
    font-size: .24rem;
    text-align: left;
    border-bottom: 1px solid #fff;
    text-indent: 1em;
}

.nav > ul li > a > i {
    position: absolute;
    right: .5rem;
    top: 0;
    line-height: 1rem;
    font-size: .5rem;
    -webkit-transition: -webkit-transform .2s;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
}

.nav > ul li > a.in > i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.nav > ul li ul {
    display: none;
}

.nav > ul li ul a {
    font-size: .3rem;
}

.nav.in {
    background: rgba(0, 0, 0, 0.5);
    visibility: visible;
}

.nav.in ul {
    right: 0;
}

.index_title1 {
    margin-right: 0.3rem;
}

.banner {
    height: 3.27rem;
    overflow: hidden;
    position: relative;
}

.banner li {
    float: left;
}

.banner li img {
    display: block;
}

.banner_btn {
    position: absolute;
    left: 0;
    bottom: 5%;
    width: 100%;
    text-align: center;
    font-size: 0;
    line-height: 0rem;
}

.banner_btn span {
    display: inline-block;
}

.banner_btn a {
    display: block;
    float: left;
    width: .2rem;
    height: .2rem;
    border-radius: 100%;
    margin: .05rem;
    background: rgba(255, 255, 255, 0.5);
    position: relative;
    -webkit-transition: background .2s;
    transition: background .2s;
}

.banner_btn a:hover,
.banner_btn a.in {
    background: white;
}

.search {
    width: 100%;
    height: 1rem;
    background-color: rgb(245, 245, 245); 
    .w1200 {
        input[type="text"] {
            width: 83%;
            height: .6rem;
            line-height: .6rem;
            float: left;
            text-indent: 1em;
            margin-top: .2rem;
        }
        input[type="button"] {
            width: 17%;
            height: .6rem;
            line-height: .6rem;
            background: url(../img/search.jpg) no-repeat center left;
            float: left;
            margin-top: .2rem;
        }
    }
}

.ad {
    .div1 {
        width: 33%;
        height: 1.5rem;
        margin-left: .5%;
        background: url(../img/ad1.jpg) no-repeat center top;
        background-size: 100% 100%;
    }
    .div2 {
        width: 33%;
        height: 1.5rem;
        background: url(../img/ad2.jpg) no-repeat center top;
        background-size: 100% 100%;
    }
    .div3 {
        width: 33%;
        height: 1.5rem;
        background: url(../img/ad3.jpg) no-repeat center top;
        background-size: 100% 100%;
    }
    p {
        text-align: center;
        font-size: .2rem;
        color: #fff;
        line-height: .3rem;
        margin-top: .4rem;
    }
}

.itit {
    width: 100%;
    margin-top: .3rem;
    h4 {
        font-size: .4rem;
        color: rgb(32, 189, 216);
        font-weight: normal;
        line-height: 1rem;
        background: url(../img/ititbg.png) no-repeat left bottom;
        span {
            font-size: .25rem;
            color: rgb(153, 153, 153);
            margin-left: .2rem;
        }
        a {
            display: block;
            padding: .1rem;
            font-size: .25rem;
            color: rgb(136, 136, 136);
            border: 1px solid rgb(234, 234, 234);
            line-height: .2rem;
            margin-top: .3rem;
        }
    }
    ul {
        margin-top: .2rem;
        li {
            float: left;
            margin-left: 2%; 
            margin-bottom: 2%; 
            width: 32%;
            a {
                display: block;
                width: 100%;
                height: .7rem;
                line-height: .7rem;
                text-align: center;
                font-size: .25rem;
                font-weight: bold;
                color: rgba(32,189,216,1);
                border: 1px solid rgba(32,189,216,1);
            }
        }
        li:nth-child(1) {
            margin-left: 0%; 
        }
        li:nth-child(4) {
            margin-left: 0%; 
        }
    }
}

.pro_catlist {
    ul {
        margin-top: .3rem;
        li {
            float: left;
            width: 48%;
            margin-left: 4%;
            height: 2.4rem;
            margin-bottom: 4%;
            a {
                display: block;
                width: 100%;
                height: 2.4rem;
                overflow: hidden;
                position: relative;
                img {
                    width: 100%;
                }
                .catename {
                    position: absolute;
                    width: 50%;
                    height: .7rem;
                    line-height: .7rem;
                    left: 25%;
                    top: .85rem;
                    color: rgb(51, 51, 51);
                    font-size: .3rem;
                    text-align: center;
                    background-color: #fff;
                    opacity: 0.75;
                }
            }
        }
        li:nth-child(1) {
            width: 100%;
            height: 3.2rem;
            margin-left: 0%;
            a {
                display: block;
                width: 100%;
                height: 3.2rem;
                overflow: hidden;
                position: relative;
                img {
                    width: 100%;
                }
            }
        }
        li:nth-child(2) {
            margin-left: 0%;
        }
        li:nth-child(4) {
            margin-left: 0%;
        }
    }
}

.about {
    width: 100%;
    margin-top: .2rem;
    p {
        font-size: .25rem;
        line-height: .5rem;
        color: rgb(102, 102, 102);
        margin-bottom: .3rem;
    }
    img {
        width: 100%;
    }
}

.news {
    ul {
        margin-top: .3rem;
        li {
            padding: .3rem 0;
            border-bottom: 1px dotted #e2e2e2;
            a {
                font-weight: bold;
                font-size: .28rem;
                color: #5a5a5a;
                line-height: .5rem;
            }
            .p1 {
                color: #999;
                font-size: .2rem;
                line-height: .4rem;
            }
            .p2 {
                color: #888;
                font-size: .2rem;
                line-height: .4rem;
            }
        }
        li:last-child {
            margin-bottom: .3rem;
        }
    }
}

.aboutus {
    width: 100%;
    margin-top: .3rem;
    img {
        width: 100%;
        margin-bottom: .2rem;
    }
    p {
        text-indent: 2em;
        font-size: .28rem;
        color: rgb(102, 102, 102);
        line-height: .5rem;
    }
}

.prolist {
    ul {
        margin-top: .3rem;
        li {
            float: left;
            width: 49%;
            margin-right: 2%;
            .img {
                display: block;
                width: 100%;
                height: 3rem;
                img {
                    width: 100%;
                    height: 3rem;
                }
            }
            .title {
                display: block;
                font-size: .25rem;
                color: #5a5a5a;
                line-height: .6rem;
                font-weight: bold;
                text-align: center;
            }
        }
        li:nth-child(2n) {
            margin-right: 0%;
        }
    }
}

.page {
    clear: both;
    font-size: .25rem;
    margin: .3rem auto;
    color: #5a5a5a;
    text-align: center;
    .current {
        color: rgba(32,189,216,1);
        border-color: rgba(32,189,216,1);
    }
    a,span {
        display: inline-block;
        padding: .2rem;
        border: 1px solid #5a5a5a;
        color: #5a5a5a;
    }
}

.zxly {
    width: 100%;
    margin-top: .3rem;
    .red {
        color: red;
    }
    .p1 {
        font-size: .28rem;
        line-height: .45rem;
        color: #5a5a5a;
    }
    input[type="text"] {
        width: 100%;
        height: .7rem;
        background-color: #f0f0f0;
    }
    textarea {
        width: 100%;
        height: 1.4rem;
        background-color: #f0f0f0;
    }
    input[type="submit"] {
        width: 100%;
        height: .7rem;
        background-color: #20bdd8;
        margin-top: .3rem;
        margin-bottom: .3rem;
        color: #fff;
        font-size: .3rem;
        line-height: .7rem;
        text-align: center;
    }
    p {
        font-size: .3rem;
        line-height: .7rem;
        color: #666;
    }
}

.contactus {
    width: 100%;
    margin-top: .3rem;
    p {
        color: rgb(51, 51, 51);
        font-size: .3rem;
        line-height: .7rem;
    }
}

.main {
    .backto {
        width: 100%;
        height: .7rem;
        background-color: #20bdd8;
        line-height: .7rem;
        color: #fff;
        font-size: .25rem;
        text-align: center;
        font-weight: bold;
        margin-bottom: .3rem;
    }
    .title {
        font-size: .35rem;
        line-height: .6rem;
        color: #5a5a5a;
        font-weight: bold;
        text-align: center;
        margin-bottom: .1rem;
    }
    .tips {
        color: #999;
        line-height: .6rem;
        text-align: center;
        margin-bottom: .1rem;
    }
    .img {
        width: 100%;
        height: 5rem;
        margin: 0 auto;
    }
    .content {
        font-size: .3rem;
        color: #333333;
        line-height: .6rem;
        text-indent: 2em;
        padding-top: 0rem;
        margin-bottom: .3rem;
    }
    .content2 {
        font-size: .3rem;
        color: #999;
        line-height: .6rem;
        padding-top: 0rem;
        a {
            color: #333;
        }
    }
    .content2:last-child {
        margin-bottom: .3rem;
    }
    .description {
        width: 100%;
        padding: .2rem;
        background: #F5F5F5;
        text-indent: 2em;
        font-size: .25rem;
        line-height: .5rem;
    }
}

.dowebok {
    width: 100%;
    margin: .3rem auto;
    font-size: 0;
}

.dowebok ul {
    height: 1.1rem;
}

.dowebok img {
    vertical-align: top;
}

.str_wrap {
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;
    position: relative;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
}

.str_move {
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    cursor: move;
}

.str_move_clone {
    display: inline-block;
    vertical-align: top;
    position: absolute;
    left: 100%;
    top: 0;
}

.str_vertical .str_move_clone {
    left: 0;
    top: 100%;
}

.str_down .str_move_clone {
    left: 0;
    bottom: 100%;
}

.str_vertical .str_move,
.str_down .str_move {
    white-space: normal;
    width: 100%;
}

.str_static .str_move,
.no_drag .str_move,
.noStop .str_move {
    cursor: inherit;
}

.str_wrap img {
    max-width: none;
}

.dowebok ul {
    margin: .3rem;
    width: 10000px;
}

.dowebok ul li {
    float: left;
    height: 1.3rem;
    width: 1.5rem;
    margin-left: 0.3rem;
}

.dowebok ul li img {
    height: 1.3rem;
    width: 1.5rem;
}

.footer {
    width: 100%;
    height: 2rem;
    background: rgb(12, 12, 12);;
    p {
        text-align: center;
    }
    .p1 {
        color: #fff;
        font-weight: bold;
        font-size: .2rem;
        line-height: .4rem;
        padding-top: .3rem;
    }
    .p2 {
        font-size: .2rem;
        color: rgb(102, 102, 102);
        line-height: .4rem;
    }
}


/****************************** media ******************************/

// @import "./components/media";
